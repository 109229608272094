<div class="wrapper">
  <div class="logo-box flex vcenter">
    <app-language-selector></app-language-selector>
    <a href="https://www.payee.tech" target="_blank">
      <img src="assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg" width="72px" />
    </a>
  </div>
  <div class="title">{{ 'HEADER.TITLE' | translate }}</div>
  <div class="help-box">
    <a href="https://payee.tech/adosoknak" target="_blank">
      <div>{{ 'HEADER.FOR_DEBTORS' | translate }}</div>
      <img src="assets/link.svg" alt="" />
    </a>
  </div>
</div>
