<app-language-selector></app-language-selector>

<div class="wrapper flex column vcenter">
  <div class="img-box flex vcenter hcenter">
    <img src="assets/red_cross.svg" height="42.86px" />
  </div>
  <div class="title flex vcenter">
    {{ "CASE_CLOSED_DIALOG.TITLE" | translate }}
  </div>
  <div class="description grow1 flex vcenter">
    {{ "CASE_CLOSED_DIALOG.DESCRIPTION" | translate }}
  </div>
  <a href="https://payee.tech">
    <button mat-button class="confirm-button">
      {{ "CASE_CLOSED_DIALOG.CONFIRM" | translate }}
    </button>
  </a>
</div>
