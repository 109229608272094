<div class="wrapper">
  <div class="data-title">{{ "DETAILS.CLIENT_NAME" | translate }}</div>
  <div class="creditor-name">{{ caseData.client_name }}</div>
  <!-- <div class="flex">
    <img class="evidence-logo" src="assets/paperclip.svg" width="34.71px" />
    <div>
      <div class="data-title">{{ "DETAILS.CASE_DESCRIPTION" | translate }}</div>
      <div *ngIf="caseData.description" class="evidence-details-box">
        <ng-container
          *ngIf="caseData.description.length <= croppedEvidenceDetailsLength"
        >
          <div class="evidence-details">{{ caseData.description }}</div>
        </ng-container>
        <ng-container
          *ngIf="caseData.description.length > croppedEvidenceDetailsLength"
        >
          <ng-container *ngIf="showFullEvidenceDetails">
            <div class="evidence-details">
              <span>{{ caseData.description }}</span>
              <img
                class="toggle-evidence-details"
                src="assets/expand_less_24px.svg"
                height="20px"
                width="20px"
                (click)="showFullEvidenceDetails = !showFullEvidenceDetails"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!showFullEvidenceDetails">
            <div class="evidence-details">{{ evidenceDetailsCropped }}</div>
            &nbsp;
            <a
              class="toggle-evidence-details"
              (click)="showFullEvidenceDetails = !showFullEvidenceDetails"
            >
              {{ "DETAILS.SHOW_FULL_EVIDENCE" | translate }}
            </a>
          </ng-container>
        </ng-container>
      </div>
      <div class="files-box flex column">
        <div class="file flex vcenter" *ngFor="let file of caseData.files">
          <span class="file-name">
            {{ getCroppedFileName(file.file_name) }}
          </span>
          <a [href]="file.storage_url" target="_blank">{{ "DETAILS.DOWNLOAD" | translate }}</a>
        </div>
      </div>
    </div>
  </div> -->
</div>
