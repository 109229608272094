<div class="container flex">
  <div class="flex grow1 hcenter vcenter">
    <div class="flex column">
      <app-full-payment [caseData]="caseData"></app-full-payment>
      <!-- <a
        class="claims-button"
        (click)="
          claimsListOpen = !claimsListOpen;
          toggleClaimsList.emit(claimsListOpen)
        "
      >
        <ng-container *ngIf="!claimsListOpen">
          <img src="assets/expand_more_24px.svg" height="24px" />
          <span>{{ "DEPOSIT.CLAIMS_BUTTON.CLOSED" | translate }}</span>
        </ng-container>
        <ng-container *ngIf="claimsListOpen">
          <img src="assets/expand_less_24px.svg" height="24px" />
          <span>{{ "DEPOSIT.CLAIMS_BUTTON.OPENED" | translate }}</span>
        </ng-container>
      </a> -->
    </div>
  </div>

  <app-bank-transfer [caseData]="caseData"></app-bank-transfer>

  <div class="flex column payment-box" *ngIf="caseData.can_pay_with_barion">
    <form [formGroup]="paymentForm" class="flex column">
      <app-custom-checkbox
        [checked]="!!aszf.value"
        (onclick)="aszf.setValue(!aszf.value)"
      >
        <span class="checkbox">
          <a href="https://www.payee.tech/legal/privacy-policy" target="_blank">
            {{ "DEPOSIT.CONFIRM_PRIVACY" | translate }}
          </a>
        </span>
      </app-custom-checkbox>
      <app-custom-checkbox
        [checked]="!!pp.value"
        (onclick)="pp.setValue(!pp.value)"
      >
        <span class="checkbox">
          <a
            href="https://www.payee.tech/legal/terms-and-conditions"
            target="_blank"
          >
            {{ "DEPOSIT.CONFIRM_TOS" | translate }}
          </a>
        </span>
      </app-custom-checkbox>
    </form>

    <button
      class="payment-button"
      mat-button
      [disabled]="paymentForm.invalid || paymentLoading"
      (click)="startPayment()"
    >
      <div class="bw-theme flex hcenter" *ngIf="paymentLoading">
        <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
        </mat-progress-spinner>
      </div>
      <ng-container *ngIf="!paymentLoading">
        <ng-container *ngIf="caseData.is_payment_plan">
          {{
            "DEPOSIT.PAY_BUTTON.INSTALLMENTS"
              | translate : installment_payment_translate
          }}
        </ng-container>
        <ng-container *ngIf="!caseData.is_payment_plan">
          {{ "DEPOSIT.PAY_BUTTON.FULL" | translate }}
        </ng-container>
      </ng-container>
    </button>
    <button
      class="payment-plan-start-button"
      mat-button
      [disabled]="paymentLoading"
      (click)="startPaymentPlan()"
      *ngIf="caseData.payment_plan_enabled"
    >
      <ng-container *ngIf="!paymentLoading">
        <ng-container>{{ "DEPOSIT.REQUEST_PP" | translate }}</ng-container>
      </ng-container>
    </button>
    <img
      class="barion-strip"
      src="assets/barion-card-strip-intl-updated.svg"
      width="286.5px"
    />
    <div class="flex vcenter secure-text">
      <img src="assets/lock.svg" />&nbsp;
      <span>{{ "DEPOSIT.SECURE_PAYMENT" | translate }}</span>
    </div>
  </div>
</div>
