<app-language-selector></app-language-selector>

<div class="wrapper flex column vcenter">
  <div class="img-box flex vcenter hcenter">
    <img src="assets/checkmark.svg" height="71.43px" />
  </div>
  <div class="title flex vcenter">
    {{ "CASE_PAID_DIALOG.TITLE" | translate }}
  </div>
  <div class="description grow1 flex vcenter">
    {{ "CASE_PAID_DIALOG.DESCRIPTION" | translate }}
  </div>
  <a href="https://payee.tech">
    <button mat-button class="confirm-button">
      {{ "CASE_PAID_DIALOG.CONFIRM" | translate }}
    </button>
  </a>
</div>
